import React from 'react';

function ScrollDown() {
    return(
        <div className="scroll-down">
              <span></span>
              <span></span>
              <span></span>
        </div>
    )
}

export default ScrollDown;